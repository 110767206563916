import React from 'react'
import BlogHeader from '../../blog_header/blog_header'
import * as classess from '../blogs_styling.module.css'

import ten_resons_img from './static/ten_resons_img.png'
import ten_resons_img_webp from './static/ten_resons_img.webp'

import six_seconds_img from './static/six_seconds_img.png'
import six_seconds_img_webp from './static/six_seconds_img.webp'

import resume_promote_img from './static/resume_promote_img.png'
import resume_promote_img_webp from './static/resume_promote_img.webp'

import criteria_img from './static/criteria_img.png'
import criteria_img_webp from './static/criteria_img.png'

import rejected_img from './static/rejected_img.png'
import rejected_img_webp from './static/rejected_img.webp'

import hiring_img from './static/hiring_img.png'
import hiring_img_webp from './static/hiring_img.webp'


import Footer from '../../../footer/footer'
import Social from '../../../social/social'
import Scroll from '../scroll/scroll'


const HiringForResumes = (props) => {
    return <React.Fragment>
        <Scroll showBelow={250} />
        <BlogHeader title=" 10 Reasons You Shouldn’t be Using Resumes for Hiring | RecruitmentBot"/>
        <div className={classess.blog_content_main}>
        <h1 className={classess.heading_one}>
        Stop Hiring People For Their Resumes
            </h1>
     
       <center>
                <picture>
                    <source srcSet={hiring_img_webp} type="image/webp" />
                    <source srcSet={hiring_img} type="image/png" />
                    <img srcSet={hiring_img_webp} alt="Main Image" loading="lazy" className={classess.main_image_styling} />
                </picture>
            </center>

            <div className={classess.content_styling}>
            Hiring a person, who's going to be an integral part of your organization, or who will add value to your product, is a big responsibility. Of course you will want to know everything you can, as quickly as you can. Are you sure you want to rely on a piece of paper they've drafted about themselves, to fill this position? A person is so much more than what’s written on their resume. 
            </div>
            <div className={classess.content_styling}>
            It’s very common for employers to find themselves with a whole team of employees who do not resonate with their job requirements. Despite the detailed planning and huge amounts of effort that is put into the hiring process, it all backfires because the very first step is so flawed; they rely on resumes. You can never be too careful when it comes to the future of your company.  
            </div>
            <h2 className={classess.heading_two}>
            10 Reasons Why You Shouldn’t be Using Resumes for Hiring
            </h2>
            <center>
                <picture>
                    <source srcSet={ten_resons_img_webp} type="image/webp" />
                    <source srcSet={ten_resons_img} type="image/png" />
                    <img srcSet={ten_resons_img_webp} alt="Main Image"loading="lazy" className={classess.main_image_styling} />
                </picture>
            </center>
           
            <div className={classess.content_styling}>
            In the initial stages of job applications, resumes are the sole decisive factor because they determine whether an applicant moves further in the process or not. However, continuing to rely on this practice is likely to result in poor hires that will cost organizations a lot of money in the longer run. It is far too easy to get away with a misleading resume, which is exactly why it’s high time they stop being used. 
            </div>
            <div className={classess.content_styling}>
            There are more problems than one could expect when it comes to hiring people based on the content of their resumes, and we’ve listed a handful: 
            </div>
          
           <ul className={classess.list_styling}>
               <li>
               <h3 className={classess.heading_three}>
            1. Resumes are exaggerated recounts of past events:
            </h3>
            <div className={classess.content_styling}>
            When you wrote your CV did your achievements seem as big as they were? If you overstated things so much then what makes you think your potential employees won’t? Instead of mentioning how candidates can add value to your firm, resumes tend to focus on self reported incidents from the past which are almost always exaggerated and blown out of proportion. The selective memory of candidates is very likely to end up with an inaccurate account of events which, frankly speaking, do not even assist you in determining their character or potential.
            </div>
               </li>
               <li>
               <h3 className={classess.heading_three}>
            2. No one spends longer than 6 seconds reading your CV:
            </h3>
            <center>
                <picture>
                    <source srcSet={six_seconds_img_webp} type="image/webp" />
                    <source srcSet={six_seconds_img} type="image/png" />
                    <img srcSet={six_seconds_img_webp} alt="Main Image" loading="lazy" className={classess.main_image_styling} />
                </picture>
            </center>
            <div className={classess.content_styling}>
            I’m sure you think this is an exaggeration - how can anyone know whether to accept or reject a CV after only 6 seconds? But that’s exactly how much time recruiters spend on average when it comes to shortlisting candidates.<br/>
            How do we know this? A study was conducted with 30 participants over a span of 10 weeks and the results were very interesting, to say the least. Researchers used gaze tracking technology and saw that recruiters spent 80% of their time on the following criteria:
            <ul className={classess.list_styling1}>
              <li>Name</li>  
              <li>Current title/company </li>  
              <li>Previous title/company</li>
              <li>Previous position start/end date</li>
              <li>Current position start/end date</li>
              <li>Education </li>
            </ul> 

            </div>
               </li>
               <li>
               <h3 className={classess.heading_three}>
            3. It is very easy to lie and omit information: 
            </h3>
            <div className={classess.content_styling}>
            No one will ever willingly mention their shortcomings - especially to someone who wants to hire them. Being in a vulnerable position is the last place anyone wants to be.
            When writing a resume, people are prone to be biased towards themselves; only sticking to occurrences that support their stance. A resume is just a document with all the greatest achievements one person has; it cannot and will not mention any mistakes a person made, or how those mistakes were overcome. Candidates sell themselves in the best context they can, which is exactly why they will omit and lie wherever they can get away with it.  

            </div>
               </li>
               <li>
               <h3 className={classess.heading_three}>
            4. Skills and competency are not the same! 
            </h3>
            <div className={classess.content_styling}>
            You thought that skills and competencies were two words for the same thing? Surprise - they’re not. <br/>
            Of course resumes are a great way to formally list achievements and experience, but when it comes to identifying behavior and values, they are pretty useless. Resumes don’t differentiate between knowing how to do something (skills), and doing it really well with expertise and ease (competencies). <br/>
            Anyone can write down that they speak 3 languages but they will also skillfully avoid mentioning they can only count to ten in them!

            </div>
               </li>
               <li>
               <h3 className={classess.heading_three}>
            5. Resumes promote inequality on many different levels:
            </h3>
            <center>
                <picture>
                    <source srcSet={resume_promote_img_webp} type="image/webp" />
                    <source srcSet={resume_promote_img} type="image/png" />
                    <img srcSet={resume_promote_img_webp} alt="Main Image" loading="lazy" className={classess.main_image_styling} />
                </picture>
            </center>
            <div className={classess.content_styling}>
            Everyone wants to show off how they are ambassadors of diversity, but without even realizing it, resumes make this less and less possible. A lot of people can’t afford education or unpaid internships, so naturally their less impressive CVs receive no attention and they get stuck in low paying jobs with unpromising future prospects. 
            It’s no secret that the privileged and educated get an upperhand in life, but if a company wishes to be diverse in the truest sense, the first step is letting go of resumes; all they do is turn income inequality into a more long lasting form called career opportunity inequality, All we need is a new rubric to determine whether someone is fit for the job or not, which many big companies are already working on. 

            </div>
               </li>
               <li>
               <h3 className={classess.heading_three}>
            6. Soft skills are just as important as hard skills:
            </h3>
            <div className={classess.content_styling}>
            Of course you can’t get a technical job without having the skill for it, but hard skills aren’t really the only thing that needs to be considered. When hiring, employers generally look for three main things: creative thinking, cultural fit, and purpose. 
            Can you solve problems? Can you make your place in the team and add value on a human level? Will both the company and employee grow together? These are the questions that matter the most because they can’t be answered by a bunch of words compiled in a self report. If the answers to these questions are clear, then past experience becomes the least important factor in predicting future success.

            </div>
               </li>
               <li>
               <h3 className={classess.heading_three}>
            7. Resumes don’t answer some key questions for hiring criteria:
            </h3>
            <center>
                <picture>
                    <source srcSet={criteria_img_webp} type="image/webp" />
                    <source srcSet={criteria_img} type="image/png" />
                    <img srcSet={criteria_img_webp} alt="Main Image" loading="lazy" className={classess.main_image_styling} />
                </picture>
            </center>
            <div className={classess.content_styling}>
            To put it simply, there are four criteria for assessing job candidates:
                <ul className={classess.list_styling1}>
                    <li>Are they qualified?</li>
                    <li>Are they interested?</li>
                    <li>Are they available?</li>
                    <li>Will they fit in?</li>
                </ul>

           </div>
               </li>

               <li>
               <h3 className={classess.heading_three}>
            8. There is no measure of accuracy:
            </h3>
            <div className={classess.content_styling}>
            No one trusts an essay without citations, but resumes we trust without proof? When you think about it, it’s a very bizarre concept. Here we have a document that holds a record of all your biggest achievements and most valuable experiences… but no way to check whether it’s accurate or not? <br/>
            Resumes aren’t even official documents, and they aren’t signed by the candidate to attest their completeness or accuracy. At the end of the day you’re just placing your trust in a stranger and hoping they didn’t lie on paper. 

            </div>
               </li>
               <li>
               <h3 className={classess.heading_three}>
            9. There are inappropriate knockout factors:
            </h3>
            <center>
                <picture>
                    <source srcSet={rejected_img_webp} type="image/webp" />
                    <source srcSet={rejected_img} type="image/png" />
                    <img srcSet={rejected_img_webp} alt="Main Image"loading="lazy" className={classess.main_image_styling} />
                </picture>
            </center>
            <div className={classess.content_styling}>
            Resumes are often shortlisted and assessed by people who don’t even have any formal training. Your entire career is put in the hands of people who might just make a decision based on a whim! As a result, it’s possible for recruiters or managers to use non-job-related (or even illegal) factors to “knock out” the CVs of qualified individuals (i.e. age, or sex, or race, the school that they attended, etc.).

            </div>
               </li>
               <li>
               <h3 className={classess.heading_three}>
            10. Resumes don’t promise a future for your firm!
            </h3>
            <div className={classess.content_styling}>
            CVs are 100% historical; they only tell a recruiter how well someone did in their previous job (which doesn’t necessarily ensure the same results for a new job). What recruiters need to know is whether you can fit into the workplace and handle company specific problems. But unfortunately, resumes don’t cover that ground, they don’t have any projections or forecasts about how you will act differently in this new working environment. At the end of the day, it’s just a guessing game. 

            </div>
               </li>
           </ul>
       <h2 className={classess.heading_two}>A Better Solution to Effective Hiring - RecruitmentBot</h2>
        <div className={classess.content_styling}>With the influx of better technology, companies have started taking measures that allow them to reach much more diverse pools of talent, with people who may have been left out of the hiring process if recruiters continued to rely on conventional methods. Slowly but surely people have begun to realize that drafting a misleading resume and then supporting it with an even more misleading interview is a bit too easy. <br/>
        The chances of a human error or bias, in the shortlisting process are pretty high when one person is manually going through hundreds of CVs, and with such high stakes would your organization be willing to take the risk? RecruitmentBot, not only eliminates recruiter bias, it increases the accuracy of the shortlisting process. 

        </div>
        <h3 className={classess.heading_three}>How does RecruitmentBot work?</h3>
        <div className={classess.content_styling}>RecruitmentBot is an artificial intelligence and rule based solution, that lets you analyze shortlist candidates for a position, and analyze them in less that a minute. Sounds absurd, right? But if a recruiter is spending 6 seconds skimming a CV, one minute doesn’t seem too bad. RecruitmentBot calculates compatibility scores of  applicants, based upon their responses to an interactive chatbot we will deploy on your HR web portal. 
<br/>
This single compatibility score is a simple comparison metric for recruiters, but it is a complex analytical model that takes into account relative compatibility of candidates for specific positions, based on their potential, aptitude, and natural inclination towards the field of interest.  
</div>

<h3 className={classess.heading_three}>Why is RecruitmentBot better than your average CV?</h3>
<div className={classess.content_styling}>
The chances of a human error or bias, in the shortlisting process are pretty high when one person is manually going through hundreds of CVs, and with such high stakes would your organization be willing to take the risk? RecruitmentBot, not only eliminates recruiter bias, it increases the accuracy of the shortlisting process. 
<br/>
If you feel like reading a resume helps you interview candidates, you are technically relying on people to answer questions that arise from documents they have drafted about themselves. With RecruitmentBot, the responses provided to the chatbot are shared with interviewers, which streamlines the process and enables them to ask targeted follow-up questions with greater insight.


</div>
<h2 className={classess.heading_two}>Final Words</h2>
<div className={classess.content_styling}>
At the end of the day, companies need to consider what exactly they are looking for. If you feel like the person with the most convincing resume and sharpest suit will be the best fit for your company, then maybe you’re looking for familiarity and not growth. No one can precisely judge a person based on what they wrote about themselves on a piece of paper; we are much more complex than that! A resume is the last thing that will give us insight into who a person truly is. 


</div>

            


        </div>
        <Social url={`https://blogs.mycareerdreams.com${props.location.path}`}/>
        <Footer/>
    </React.Fragment>
}
export default HiringForResumes;