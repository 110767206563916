import React from 'react'
import HiringForResumes from '../components/recruitment_blogs/blogs/hiring_for_resumes/hiring_for_resumes'
import SEO from '../components/seo'

const Page = (location) => {
  return (<React.Fragment>
      <SEO title="10 Reasons You Shouldn’t be Using Resumes for Hiring | RecruitmentBot"
        description="Still reading resumes to hiring people? Here’s 10 reasons why you should stop now. Read this article for a better solution to effective hiring."
        url="/hiring-for-resumes/"
        image="https://blogs.mycareerdreams.com/blogs/hiring_img.webp"
        type="article"
      />
    <HiringForResumes location={location}/>
  </React.Fragment>
  )
}
export default Page;